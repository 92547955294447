import { mapHelper } from "@/utils/common.js";

// 发布状态
const shelfSts = [
  {
    value: 0,
    label: "待上架",
  },
  {
    value: 1,
    label: "上架",
  },
  {
    value: 2,
    label: "下架",
  },
];

const { map: shelfStsMap, setOps: setShelfStsOps } = mapHelper.setMap(shelfSts);

// 审核状态
const auditStatus = [
  {
    value: 0,
    label: "待审核",
  },
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核不通过",
  },
];

const { map: auditStatusMap, setOps: setAuditStatusOps } =
  mapHelper.setMap(auditStatus);

// // 板块类型
// const goodsType = [
//   {
//     value: 1,
//     label: "普通商品",
//   },
//   {
//     value: 2,
//     label: "生鲜",
//   },
//   {
//     value: 3,
//     label: "商超",
//   },
//   {
//     value: 4,
//     label: "旅游",
//   },
// ];

// const { map: goodsTypeMap, setOps: setGoodsTypeOps } =
//   mapHelper.setMap(goodsType);

// 主体类型
const principalType = [
  {
    value: 1,
    label: "个体户",
  },
  {
    value: 2,
    label: "企业",
  },
];

const { map: principalTypeMap, setOps: setPrincipalTypeOps } =
  mapHelper.setMap(principalType);

// 账户类型
const accountType = [
  {
    value: 1,
    label: "对公银行账户",
  },
  {
    value: 2,
    label: "经营者个人银行卡",
  },
];

const { map: accountTypeMap, setOps: setAccountTypeOps } =
  mapHelper.setMap(accountType);

// 团购类型
const buyType = [
  {
    value: 1,
    label: "社区团购",
  },
  {
    value: 2,
    label: "限时秒杀",
  },
];

const { map: buyTypeMap, setOps: setBuyTypeOps } = mapHelper.setMap(buyType);

// 成团状态
const groupStatus = [
  {
    value: 1,
    label: "未开始",
  },
  {
    value: 2,
    label: "进行中",
  },
  {
    value: 3,
    label: "已结束",
  },
  {
    value: 99,
    label: "成团失败",
  },
];

const { map: groupStatusMap, setOps: setGroupStatusOps } =
  mapHelper.setMap(groupStatus);

// 商家状态
const businessStatus = [
  {
    value: 0,
    label: "待审核",
  },
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核不通过",
  },
  {
    value: 3,
    label: "冻结中",
  },
  // {
  //   value: 4,
  //   label: "申述中",
  // },
  // {
  //   value: 5,
  //   label: "申述失败",
  // },
];

const { map: businessStatusMap, setOps: setBusinessStatusOps } =
  mapHelper.setMap(businessStatus);

// 订单状态
const orderStatus = [
  {
    value: 1,
    label: "待发货",
  },
  {
    value: 2,
    label: "已发货",
  },
  {
    value: 3,
    label: "已取消",
  },
  {
    value: 4,
    label: "已收货",
  },
];

const { map: orderStatusMap, setOps: setOrderStatusOps } =
  mapHelper.setMap(orderStatus);

// 订单列表-状态
const status = [
  {
    value: 2,
    label: "待收货",
  },
  {
    value: 4,
    label: "已收货",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

//商品类型

const goodsStatus = [
  {
    value: 1,
    label: "社区团购",
  },
  {
    value: 2,
    label: "限时秒杀",
  },
  {
    value: 3,
    label: "普通商品",
  },
];

const { map: goodsStatusMap, setOps: setgoodsStatusOps } =
  mapHelper.setMap(goodsStatus);

export {
  shelfStsMap,
  setShelfStsOps,
  // goodsTypeMap,
  // setGoodsTypeOps,
  auditStatusMap,
  setAuditStatusOps,
  principalTypeMap,
  setPrincipalTypeOps,
  accountTypeMap,
  setAccountTypeOps,
  buyTypeMap,
  setBuyTypeOps,
  groupStatusMap,
  setGroupStatusOps,
  businessStatusMap,
  setBusinessStatusOps,
  orderStatusMap,
  setOrderStatusOps,
  statusMap,
  setStatusOps,
  setgoodsStatusOps,goodsStatusMap,
};
