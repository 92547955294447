// 获取团长审核列表
const getGroupLeaderListURL = `/gateway/hc-serve/manage/communityBuy/getGroupLeaderList`;
// 获取团长申请详情
const getGroupLeaderDetailsURL = `/gateway/hc-serve/manage/communityBuy/getGroupLeaderDetails`;
// 团长审核
const auditGroupLeaderURL = `/gateway/hc-serve/manage/communityBuy/auditGroupLeader`;
// 团购/秒杀列表
const issueGroupBuyingForManaURL = `/gateway/hc-serve/manage/communityBuy/issueGroupBuyingForMana`;
// 团购/秒杀详情
const getGroupBuyingDetailsForManaURL = `/gateway/hc-serve/manage/communityBuy/getGroupBuyingDetailsForMana`;
// 团购/秒杀删除
const delGroupInfoURL = `/gateway/hc-serve/manage/communityBuy/delGroupInfo`;
// 团购/秒杀新增/编辑
const issueGroupBuyingURL = `/gateway/hc-serve/mini/communityBuy/issueGroupBuying`;

// 获得订单列表
const getOrderListURL = `/gateway/hc-serve/manage/communityBuy/getOrderList`;
// 取消订单
const cancelOrderURL = `/gateway/hc-serve/manage/communityBuy/cancelOrder`;
// 退款
const refundOrderURL = `/gateway/hc-serve/mini/communityBuy/refundOrder`;
// 查询退款订单状态
const queryRefundOrderURL = `/gateway/hc-serve/mini/communityBuy/queryRefundOrder`;

// 一刻钟冻结/解冻
const updateStatusURL = `/gateway/hc-serve/manage/quarterMall/updateStatus`;
// 普通/一刻钟商品审核
const auditGoodsInfoURL = `/gateway/hc-serve/manage/quarterMall/auditGoodsInfo`;
// 普通商品详情
const getGoodsDetailsURL = `/gateway/hc-serve/manage/quarterMall/getGoodsDetails`;
// 普通新增编辑商品
const addOrUpdateGoodsInfoURL = `/gateway/hc-serve/manage/quarterMall/addOrUpdateGoodsInfo`;
// 普通/一刻钟上/下架
const updShelfStsStatusURL = `/gateway/hc-serve/manage/quarterMall/updShelfStsStatus`;
// 一刻钟商品列表 - 普通商品列表
const getGoodsListURL = `/gateway/hc-serve/manage/quarterMall/getGoodsList`;
// 一刻钟商品删除
const delGoodsInfoURL = `/gateway/hc-serve/manage/quarterMall/delGoodsInfo`;
// 社区
const getSpaceTreeListURL = `/gateway/hc-space/space/getSpaceTreeList`;
// 获取一刻钟商品icon图
const getMallTypeIconListURL = `/gateway/hc-serve/miniapi/quarterMall/getMallTypeIconList`;
// 管理后台一刻钟新增商品时的商户列表-商品新增选择框
const getQuarterShopListURL = `/gateway/hc-serve/manage/quarterMall/getQuarterShopList`;
// 一刻钟查询商户列表
const getShopInfoListURL = `/gateway/hc-serve/miniapi/quarterMall/getShopInfoList`;
// 一刻钟商户详情
const detailByIdURL = `/gateway/hc-serve/miniapi/quarterMall/detailById`;
// 一刻钟商品详情
const addOrUpdateQuarterGoodsInfoURL = `/gateway/hc-serve/manage/quarterMall/addOrUpdateQuarterGoodsInfo`;
// 一刻钟商品订单列表
const getOrderListForManageURL = `/gateway/hc-serve/manage/quarterMall/getOrderListForManage`;
// 获取可用的sub_mch_id
const getSubMchIdsURL = "/gateway/hc-serve/mini/communityBuy/getSubMchIds";

// 订单收货列表
const getReceiveOrderListURL =
  "/gateway/hc-serve/manage/communityBuy/getReceiveOrderList";
// 排序
const sortShop = "/gateway/hc-serve/manage/quarterMall/sortShop";
// 解密
const getDecPhoneUrl = "/gateway/hc-serve/manage/communityBuy/getDecPhone";
// 线上店铺商家
const quarterMallGetDecPhoneUrl =
  "/gateway/hc-serve/miniapi/quarterMall/getDecPhone";
//团购秒杀
const getGroupBuyDecPhoneUrl =
  "/gateway/hc-serve/manage/communityBuy/getGroupBuyDecPhone";
//线上店铺订单
const quarterMallGetDecPhone =
  "/gateway/hc-serve/manage/quarterMall/getDecPhone ";

//orderDetail
const pageOrderList = "/gateway/hc-serve/manage/communityBuy/pageOrderList"
//导出excel
const exportOrderList = "/gateway/hc-serve/manage/communityBuy/exportOrderList"

export {
  quarterMallGetDecPhone,
  getGroupBuyDecPhoneUrl,
  getDecPhoneUrl,
  quarterMallGetDecPhoneUrl,
  getGroupLeaderListURL,
  getGroupLeaderDetailsURL,
  auditGroupLeaderURL,
  issueGroupBuyingForManaURL,
  getGroupBuyingDetailsForManaURL,
  delGroupInfoURL,
  issueGroupBuyingURL,
  getOrderListURL,
  cancelOrderURL,
  refundOrderURL,
  queryRefundOrderURL,
  updateStatusURL,
  auditGoodsInfoURL,
  getGoodsDetailsURL,
  addOrUpdateGoodsInfoURL,
  updShelfStsStatusURL,
  getGoodsListURL,
  delGoodsInfoURL,
  getSpaceTreeListURL,
  getMallTypeIconListURL,
  getQuarterShopListURL,
  getShopInfoListURL,
  detailByIdURL,
  addOrUpdateQuarterGoodsInfoURL,
  getOrderListForManageURL,
  getSubMchIdsURL,
  getReceiveOrderListURL,
  sortShop,
  pageOrderList,
  exportOrderList
};
