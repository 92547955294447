<template>
  <div class="section">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot v-if="isBusiness">
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标题" clearable v-model="searchParam.title" />
        <v-select
          clearable
          :options="shelfStsOps"
          v-model="searchParam.shelfSts"
          label="状态"
        />
        <v-select
          clearable
          :options="goodsTypeOps"
          v-model="searchParam.goodsType"
          label="板块"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" @click="toView(scope.row)" />
        <template v-if="!isBusiness">
          <v-button
            text="审核"
            v-if="scope.row.auditStatus == 0"
            type="text"
            @click="toAudio(scope.row)"
          />
        </template>
        <template v-else>
          <v-button
            text="上架"
            type="text"
            v-if="scope.row.shelfSts == 0 || scope.row.shelfSts == 2"
            @click="changeShelfSts(scope.row, 1)"
          />
          <v-button
            text="下架"
            type="text"
            v-if="scope.row.shelfSts == 1"
            @click="changeShelfSts(scope.row, 2)"
          />
          <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        </template>

        <v-button text="删除" type="text" @click="toCancel(scope.row)" />
      </template>
    </v-list>

    <v-dialog
      title="审核"
      v-model="showDialog"
      width="380px"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
    >
      <form-panel
        ref="formPanel"
        :form="form"
        :hasHeader="false"
        :footerShow="false"
      >
        <el-form-item
          label="审核状态"
          :rules="[
            { required: true, message: '请选择审核状态', trigger: 'change' },
          ]"
          prop="auditStatus"
        >
          <v-select
            :options="auditStatusOps"
            v-model="form.auditStatus"
          ></v-select
        ></el-form-item>
      </form-panel>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getGoodsListURL,
  updShelfStsStatusURL,
  delGoodsInfoURL,
  getMallTypeIconListURL,
  auditGoodsInfoURL,
} from "./api.js";
import {
  shelfStsMap,
  setShelfStsOps,
  auditStatusMap,
  setAuditStatusOps,
  // goodsTypeMap,
  // setGoodsTypeOps,
} from "./map.js";

export default {
  data() {
    return {
      shelfStsOps: setShelfStsOps(),
      auditStatusOps: setAuditStatusOps().slice(1),
      // goodsTypeOps: setGoodsTypeOps(),
      goodsTypeOps: [],
      searchParam: {
        title: "",
        shelfSts: "",
        goodsType: "",
      },
      communitList: [],
      tableUrl: getGoodsListURL,
      headers: [
        {
          prop: "title",
          label: "标题",
        },
        {
          prop: "tenantName",
          label: "所在社区",
        },
        {
          prop: "typeName",
          label: "板块",
        },
        {
          prop: "insertTime",
          label: "发布时间",
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter(row, prop) {
            return auditStatusMap[row[prop]];
          },
        },
        {
          prop: "shelfSts",
          label: "状态",
          formatter(row, prop) {
            return shelfStsMap[row[prop]];
          },
        },
      ],

      /** v-dialog */
      showDialog: false,
      curItem: null,
      form: {
        auditStatus: undefined,
      },
    };
  },
  computed: {
    ...mapState("app", ["userInfo"]),
    isBusiness() {
      /**  
        商户:新增、上下架、编辑、删除
        运营：审核、删除
       */
      // 相等则表示为 商户账号
      return this.userInfo.roleId == "1687225075227278116";
    },
  },
  created() {
    this.getMallTypeIconList();
  },
  methods: {
    toView(item) {
      this.$router.push({
        name: "businessNewGoodsForm",
        query: { id: item.id, isView: 1 },
      });
    },
    toAudio(item) {
      this.curItem = item;
      this.form.auditStatus = undefined;
      this.$nextTick(() => {
        if (this.$refs.formPanel) {
          this.$refs.formPanel.clearValidate();
        }
      });
      this.showDialog = true;
    },
    toAdd() {
      this.$router.push({
        name: "businessNewGoodsForm",
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "businessNewGoodsForm",
        query: { id: data.id },
      });
    },
    toCancel(data) {
      this.$confirm("你确定要删除吗", "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(delGoodsInfoURL, null, {
              params: {
                id: data.id,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("删除成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },
    changeShelfSts(data, shelfSts) {
      let message = "确定要上架" + data.title;
      if (shelfSts == 2) {
        message = "确定要下架" + data.title;
      }

      this.$confirm(message, "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(`${updShelfStsStatusURL}`, null, {
            params: {
              id: data.id,
              shelfSts: shelfSts,
            },
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },

    // 获取商品icon图
    async getMallTypeIconList() {
      let res = await this.$axios.get(getMallTypeIconListURL);
      if (res.code == 200) {
        this.goodsTypeOps = res.data
          .filter((v) => !!v.isShow)
          .map((v) => ({
            value: v.id,
            label: v.typeName,
            ...v,
          }));
      }
    },

    /** v-dialog */
    dialogCancel() {
      this.showDialog = false;
    },
    dialogConfirm() {
      const result = this.$refs.formPanel.validate();
      if (!result) {
        return;
      }
      this.$axios
        .post(auditGoodsInfoURL, null, {
          params: {
            id: this.curItem.id,
            auditStatus: this.form.auditStatus,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("审核成功");
            this.$refs.list.search();
            this.showDialog = false;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
}
</style>
